@use 'sass:map';
@use '@angular/material' as mat;

// Note: Color palettes are generated from primary: #0050a0
$_palettes: (
  primary: (
    0: #000000,
    10: #001b3e,
    20: #003064,
    25: #003a78,
    30: #00468d,
    35: #0351a1,
    40: #1e5dae,
    50: #3f77c9,
    60: #5c91e5,
    70: #7aacff,
    80: #aac7ff,
    90: #d6e3ff,
    95: #ecf0ff,
    98: #f9f9ff,
    99: #fdfbff,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #061b39,
    20: #1e314f,
    25: #293c5a,
    30: #354766,
    35: #415373,
    40: #4d5f7f,
    50: #657799,
    60: #7f91b4,
    70: #99acd0,
    80: #b5c7ed,
    90: #d6e3ff,
    95: #ecf0ff,
    98: #f9f9ff,
    99: #fdfbff,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #320046,
    20: #4e1166,
    25: #5a2072,
    30: #672c7e,
    35: #74398b,
    40: #814598,
    50: #9c5eb3,
    60: #b878cf,
    70: #d592eb,
    80: #edb1ff,
    90: #f9d8ff,
    95: #feebff,
    98: #fff7fb,
    99: #fffbff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #191c21,
    20: #2e3036,
    25: #393b41,
    30: #45474d,
    35: #505258,
    40: #5c5e64,
    50: #75777d,
    60: #8f9097,
    70: #aaabb2,
    80: #c5c6cd,
    90: #e1e2e9,
    95: #f0f0f8,
    98: #f9f9ff,
    99: #fdfbff,
    100: #ffffff,
    4: #0c0e13,
    6: #111318,
    12: #1d2025,
    17: #282a2f,
    22: #32353a,
    24: #37393f,
    87: #d9d9e1,
    92: #e7e8ef,
    94: #ededf5,
    96: #f3f3fb,
  ),
  neutral-variant: (
    0: #000000,
    10: #171c25,
    20: #2c303a,
    25: #373b46,
    30: #424751,
    35: #4e525d,
    40: #5a5e69,
    50: #727783,
    60: #8c919d,
    70: #a7abb8,
    80: #c2c6d3,
    90: #dee2ef,
    95: #edf0fe,
    98: #f9f9ff,
    99: #fdfbff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes, neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary,
    tertiary: $_tertiary,
    use-system-variables: true,
  ),
  typography: (
    use-system-variables: true,
  ),
));

$dark-theme: mat.define-theme((
  color: (
    theme-type: dark,
    primary: $_primary,
    tertiary: $_tertiary,
    use-system-variables: true,
  ),
  typography: (
    use-system-variables: true,
  ),
));

@include mat.core();

:root {
  @include mat.all-component-themes($light-theme);
  @include mat.system-level-colors($light-theme, (
    surface: map.get($_palettes, neutral, 100),
    surface-container-low: var(--sys-surface)
  ));
  @include mat.system-level-typography($light-theme);

  // Added for light theme of toolbar
  --sys-light-primary: #{map.get($_primary, 40)};
  --sys-light-on-primary: #{map.get($_primary, 100)};

  --mdc-icon-button-icon-color: var(--sys-primary);
  --mat-sidenav-container-width: auto;
  --mat-divider-color: var(--sys-outline-variant);
}

@media (prefers-color-scheme: dark) {
  :root {
    @include mat.system-level-colors($dark-theme, (
      surface: #2c2c2c,
      surface-container-low: var(--sys-surface)
    ));
  }
}

@include mat.typography-hierarchy($light-theme);

.mat-typography {
  font: var(--sys-body-medium);

  h1 {
    font: var(--sys-headline-small);
    letter-spacing: var(--sys-headline-small-tracking);
  }

  h2 {
    font: var(--sys-title-large);
    letter-spacing: var(--sys-title-large-tracking);
  }

  h3 {
    font: var(--sys-title-medium);
    letter-spacing: var(--sys-title-medium-tracking);
  }
}

.mat-error {
  color: var(--sys-error);
}

.danger-button {
  @include mat.button-color($light-theme, $color-variant: error);
}

.danger-icon-button {
  @include mat.icon-button-color($light-theme, $color-variant: error);
}

:root {
  --alert-background-color: var(--sys-primary-container);
  --alert-color: var(--sys-on-primary-container);
}

.alert-danger {
  --alert-background-color: var(--sys-error-container);
  --alert-color: var(--sys-on-error-container);
}
