/* You can add global styles to this file, and also import other style files */
@import 'theme';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

mat-form-field {
  width: 100%;
}

a {
  color: var(--sys-primary) !important;
  @apply font-bold;
}

b, strong {
  @apply font-bold;
}

::-webkit-scrollbar {
  width: 4px;
  overflow-y: scroll;
  background: grey;
  box-shadow: inset 0 0 4px #707070;
}

::-webkit-scrollbar-thumb {
  background: var(--sys-primary);
  border-radius: 10px;
}
